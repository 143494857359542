/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/3d8af05b1dbb5df8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/a50efca067c45ff7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/af961b7eb9a15f7e-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/c0058a8df935bb33-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/dbe242b5c3b9d8cb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/3511fdf6750b518d-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/02205c9944024f15-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/83e4d81063b4b659-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/bd976642b4f7fd99-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/de9eb3a9f0fa9e10-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/44c3f6d12248be7f-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/71ba03c5176fbd9c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4a8324e71b197806-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/7d8c9b0ca4a64a5a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7c53f7419436e04b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1c57ca6f5208a29b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cff529cd86cc0276-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7be645d133f3ee22-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5647e4c23315a2d2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3dbd163d3bb09d47-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/934c4b7cb736f2a3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/dfa8b99978df7bbc-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/42d52f46a26971a3-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a6d330d7873e7320-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a5b77b63ef20339c-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d117eea74e01de14-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/eb52b768f62eeeb4-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/627622453ef56b0d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e25729ca87cc7df9-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/912a9cfe43c928d9-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/baf12dd90520ae41-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/bbdb6f0234009aba-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f06116e890b3dadb-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8fb72f69fba4e3d2-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0e4fe491bf84089c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/46c894be853ec49f-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/9e48537b1b020091-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/37b0c0a51409261e-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/506bd11311670951-s.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/80a2a8cc25a3c264-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/94300924a0693016-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_9bfd88';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/8db47a8bf03b7d2f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_9bfd88';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_9bfd88 {font-family: '__Roboto_9bfd88', '__Roboto_Fallback_9bfd88';font-style: normal
}



* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(64, 64, 64, 255);
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(64, 64, 64, 255);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(132, 132, 132, 255);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(60, 60, 60, 255);
}


body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb)))
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

