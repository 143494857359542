

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(64, 64, 64, 255);
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(64, 64, 64, 255);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(132, 132, 132, 255);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(60, 60, 60, 255);
}


body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb)))
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
